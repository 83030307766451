<template>
    <noscript>
        <iframe
            src="https://www.googletagmanager.com/ns.html?id=GTM-WL9RHQ94"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
        >
        </iframe>
    </noscript>
    <CommonComponentsModalApplyTrial />
    <CommonComponentsModalUserRoleSelector />
    <main class="default">
        <!-- <CommonComponentsHeader /> -->
        <template v-if="headerVisible">
            <CommonComponentsHeaderPrePc v-if="!isMobile" />
            <CommonComponentsHeader v-else />
        </template>
        <!-- 在线咨询 -->
        <CommonComponentsHomeOnlineConsult
            :topVisible="topVisible"
            v-if="(isPcCn || isMobile) && !store.isMobileMenuOpen"
        />
        <!-- <span class="xxxx">{{ !store.isMobileMenuOpen }}</span> -->
        <slot />
        <CommonComponentsFooter />
    </main>
</template>

<script setup lang="ts">
    import { onMounted, ref } from 'vue';
    import { lazyloaderUpdate } from '../composables/useLazyload';
    import { getIsPcCnRef, getIsMobileRef, getIsMobileCnRef } from '~~/composables/useMatchRules.ts';
    import { useWindowStore } from '~~/store/windowStore';

    const store = useWindowStore();
    const isPcCn = getIsPcCnRef();
    const isMobileCn = getIsMobileCnRef();
    const isMobile = getIsMobileRef();
    const topVisible = ref(false);
    const headerVisible = ref(false);

    const appearBackTop = () => {
        // console.log('window.scrollY', window.scrollY);
        // console.log('window.innerHeight', window.innerHeight * 2);
        if (window.scrollY >= window.innerHeight * 2) {
            // console.log('topVisible.value = true;', (topVisible.value = true));
            topVisible.value = true;
        }
        if (window.scrollY < window.innerHeight * 2) {
            // console.log('topVisible.value = true;', (topVisible.value = true));
            topVisible.value = false;
        }
    };

    onMounted(() => {
        headerVisible.value = true;
        // 如果模版中的某些含有异步资源，需额外添加一行进行刷新，确保在模版切换后至少能刷新一次
        lazyloaderUpdate();
        window.addEventListener('scroll', appearBackTop);
    });
</script>

<style lang="less">
    main {
        width: 100%;
    }
</style>
